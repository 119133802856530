import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  :global(.color-gray) {
    :global(circle) {
      fill: ${COLORS.black900};
      opacity: 0.16;
    }
  }
  :global(.color-white) {
    :global(path) {
      fill: white;
    }
    :global(circle) {
      fill: white;
      opacity: 0.16;
    }
  }
  :global(.color-brown.icon-coin) {
    :global(path) {
      fill: ${COLORS.brown500};
      stroke: transparent;
    }
    :global(circle) {
      fill: ${COLORS.brown500};
      opacity: 0.16;
    }
  }
  :global(.color-red.icon-coin) {
    :global(path) {
      fill: ${COLORS.red500};
      stroke: transparent;
    }
    :global(circle) {
      fill: ${COLORS.red500};
      opacity: 0.16;
    }
  }
  :global(.disabled.icon-coin) {
    :global(circle) {
      fill: ${COLORS.black600};
      opacity: 0.16;
    }
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
  :global(.color-black600.icon-coin) {
    :global(circle) {
      fill: ${COLORS.purple200};
      opacity: 1;
    }
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
  :global(.color-black500.icon-coin) {
    :global(circle) {
      fill: ${COLORS.purple200};
      opacity: 1;
    }
    :global(path) {
      fill: ${COLORS.black500};
    }
  }
  :global(.color-purple.icon-coin) {
    :global(circle) {
      fill: ${COLORS.purple200};
      opacity: 1;
    }
  }
`;

export default styles;
